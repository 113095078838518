<template>
    <div class="content">
        <header class="section faq my-6">
            <h1 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">{{ $t("navigation.help") }}</h1>
            <p class="is-size-4-desktop is-size-5-tablet">
                {{ $t("message.help.help_desc") }}
            </p>
        </header>
        <main class="section faq my-6">
            <o-collapse animation="slide" v-for="(collapse, index) of collapses" :key="index" :open="isOpen == index" @open="isOpen = index">
                <div slot="trigger" slot-scope="props" class="collapse-header" role="button">
                    <p class="collapse-title is-size-5-desktop is-size-6-tablet">
                        {{ collapse.title }}
                    </p>
                    <a class="collapse-icon has-text-white">
                        <o-icon :icon="props.open ? 'caret-up' : 'caret-down'"> </o-icon>
                    </a>
                </div>
                <div class="collapse-content has-background-white">
                    <div class="content has-text-left has-text-grey-darker" v-html="collapse.text">
                    </div>
                </div>
            </o-collapse>
        </main>
    </div>
</template>

<script>
/**
 * Help view
 * Display the Help page
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'Help',
    data: function() {
        return {
            isOpen: 0
        }
    },
    computed: {
        /**
         * Collapsibles content
         * Computed functions are needed for i18n reactivity
         */
        collapses: function() {
            return [
                {
                    title: this.$t("message.help.q1"),
                    text: this.$t("message.help.q1_desc")
                },
                {
                    title: this.$t("message.help.q2"),
                    text: this.$t("message.help.q2_desc")
                },
                {
                    title: this.$t("message.help.q3"),
                    text: this.$t("message.help.q3_desc")
                },
                {
                    title: this.$t("message.help.q4"),
                    text: this.$t("message.help.q4_desc")
                },
                {
                    title: this.$t("message.help.q9"),
                    text: this.$t("message.help.q9_desc")
                },
                {
                    title: this.$t("message.help.q5"),
                    text: this.$t("message.help.q5_desc")
                },
                {
                    title: this.$t("message.help.q6"),
                    text: this.$t("message.help.q6_desc")
                },
                {
                    title: this.$t("message.help.q7"),
                    text: this.$t("message.help.q7_desc")
                },
                {
                    title: this.$t("message.help.q8"),
                    text: this.$t("message.help.q8_desc", {locale: this.$store.getters['user/getLocale'], contact: this.$t('navigation.contact_url')})
                }
            ];
        }
    },
    metaInfo: function() {
        return {
            title: `${this.$t("message.help.meta.title")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.help.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				},
                {
                    name: 'og:title',
                    content: `${this.$t("message.help.meta.title")} | ${this.$t('message.title')}`
                },
                {
                    name: 'og:image',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:secure_url',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:type',
                    content: 'image/png'
                },
                {
                    name: 'og:image:alt',
                    content: 'Seenjab logo small'
                },
                {
                    name: 'og:description',
                    content: this.$t('message.help.meta.description'),
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

@media(min-width: $desktop-landscape) {
    .faq {
        width: 50%;
        margin: auto;
    }
    .collapse-content {
        opacity: 0.7;
    }
}
</style>
